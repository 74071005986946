var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "add-role-card",
      attrs: { height: "139", flat: "", light: "" },
      on: { click: _vm.createRole }
    },
    [
      _c(
        "v-card-text",
        [
          _c("StIcon", {
            staticClass: "add-role-card__icon",
            attrs: { "icon-name": "plus-circle-outline" }
          }),
          _c("div", { staticClass: "add-role-card__text px-0" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("Role.roleList.addRole")) + "\n    "
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }