var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "role-list" },
    [
      _c(
        "v-container",
        { staticClass: "role-list__main-title", attrs: { fluid: "" } },
        [
          _c("h2", { staticClass: "role-list-title" }, [
            _c("b", [_vm._v(_vm._s(_vm.$t("Role.roleList.roleListTitle")))])
          ]),
          _c("p", { staticClass: "role-list__description" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("Role.roleList.roleListDescription")) +
                "\n    "
            )
          ])
        ]
      ),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "h3",
            { staticClass: "mb-6 role-list__green-title" },
            [
              _c("StIcon", { attrs: { "icon-name": "hexagon-multiple" } }),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("Role.roleList.roleListSubtitle")) +
                  "\n    "
              )
            ],
            1
          ),
          _c("StReloadAndLoaderWrapper", {
            staticClass: "role-list__wrapper",
            attrs: { "request-status": _vm.requestStatus },
            on: { reload: _vm.reload },
            scopedSlots: _vm._u([
              {
                key: "wrappedComponent",
                fn: function() {
                  return [
                    _vm.success
                      ? _c("RoleCards", { attrs: { roles: _vm.roles } })
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }