<template>
  <v-card
    v-resize="setCurrentWidth"
    height="139"
    min-height="139"
    class="role-card"
    :class="internalClass"
    light
    @click="clickInCard"
  >
    <v-card-text>
      <div class="role-card__title-line">
        <span
          v-tooltip="title"
          class="role-card__title"
        >
          {{ title }}
        </span>
        <StIcon
          v-if="!internal"
          v-tooltip="roleTooltip"
          icon-name="pencil"
          class="role-card__icon"
          size="13"
        />
      </div>
      <div class="role-card__users">
        {{ userCount }} {{ $tc('Role.user', userCount) }}
      </div>
    </v-card-text>

    <v-card-actions class="role-card__actions">
      <StUserAvatar
        v-for="user in userAvatars"
        :key="user.name"
        :user="user"
        :tooltip="user.name"
        :user-image-path="'avatarUrl'"
        size="34"
        color="grey lighten-4"
      />

      <StIcon
        v-if="showMore"
        v-tooltip="$t('Role.roleCard.showMoreIcon')"
        :icon-name="showMoreIcon"
        class="role-card__icon-dots-horizontal"
        size="19"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import StUserAvatar from '@stilingue/st-user-avatar/StUserAvatar';
import StIcon from '@stilingue/st-icon/StIcon';

export default {
  name: 'RoleCard',
  components: {
    StUserAvatar,
    StIcon
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    users: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    internal: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      currentWidth: 300,
      showMoreIcon: 'dots-horizontal',
      waitRenderAndAdjustDelay: 100,
      cardSidePadding: 115,
      profilePicSize: 34
    };
  },
  computed: {
    userCount() {
      return this.users.length;
    },
    showMore() {
      return this.userCount > this.maxUsersPerCard;
    },
    userAvatars() {
      if (!this.showMore) return this.users;
      return this.users.slice(0, this.maxUsersPerCard);
    },
    roleTooltip() {
      return this.internal
        ? this.$i18n.t('Role.roleList.isDefaultRole')
        : this.$i18n.t('Role.roleList.isCustomRole');
    },
    maxUsersPerCard() {
      const usersPerCard = Math.floor((this.currentWidth - this.cardSidePadding) / this.profilePicSize);
      return Math.max(usersPerCard, 5);
    },
    internalClass() {
      return { 'role-card--internal': this.internal };
    }
  },
  mounted() {
    this.waitAndReadjustWidth();
  },
  methods: {
    setCurrentWidth() {
      this.currentWidth = this.$el.offsetWidth;
    },
    clickedInShowMoreIcon(e) {
      return Boolean(e.target && e.target.className && e.target.className.includes(this.showMoreIcon));
    },
    clickInCard(e) {
      if (this.clickedInShowMoreIcon(e)) return this.emitOnRoleClicked('users-in-role');

      return this.emitOnRoleClicked('edit-role');
    },
    emitOnRoleClicked(goToTab) {
      return this.$emit('on-role-clicked', {
        goToTab,
        roleId: this.id
      });
    },
    waitAndReadjustWidth() {
      // NextTick dont work, because of the
      // scroll in the page is compressing the card
      // and this event is not captured and happens
      // after the nextTick
      setTimeout(() => {
        this.setCurrentWidth();
      }, this.waitRenderAndAdjustDelay);
    }
  }
};
</script>

<style lang="scss" scoped>
  .role-card {
    border-radius: 6px;
    border: solid 1px var(--gray-500);
    cursor: pointer;

    &__title {
      vertical-align: middle;
      font-size: 16px;
      color: var(--gray-200);
      font-weight: bold;
      overflow: hidden;
      max-width: 195px;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__icon {
      vertical-align: middle;
      margin-left: 7px;
    }

    &__actions {
      padding: 0;
    }

    &__users {
      font-size: 12px;
      color: var(--gray-300);
      text-transform: uppercase;
      font-weight: bold;
    }

    &::v-deep .v-avatar {
      margin-right: 5px;

      &:first-of-type {
        margin-left: 24px;
      }
    }

    &__icon-dots-horizontal {
      border-radius: 50px;
      background-color: var(--green-agua-400);
      padding: 8px;
      color: var(--white);
    }

    &__title-line {
      margin-bottom: 2px;
    }

    ::v-deep {
      margin-right: 18px;

      .v-card__text {
        padding: 24px 24px 12px 24px;
      }
    }
  }

</style>
