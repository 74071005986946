var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "role-cards", attrs: { fluid: "" } },
    [
      _c("div", { staticClass: "role-cards__role" }, [_c("AddRoleCard")], 1),
      _vm._l(_vm.roles, function(role) {
        return _c(
          "div",
          { key: role.id, staticClass: "role-cards__role" },
          [
            _c("RoleCard", {
              attrs: {
                id: role.id,
                title: role.name,
                users: role.users || [],
                internal: role.internal
              },
              on: { "on-role-clicked": _vm.editRole }
            })
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }