<template>
  <v-card
    height="139"
    flat
    light
    class="add-role-card"
    @click="createRole"
  >
    <v-card-text>
      <StIcon
        class="add-role-card__icon"
        icon-name="plus-circle-outline"
      />
      <div class="add-role-card__text px-0">
        {{ $t('Role.roleList.addRole') }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import StIcon from '@stilingue/st-icon/StIcon';

export default {
  name: 'AddRoleCard',
  components: {
    StIcon
  },
  methods: {
    createRole() {
      this.$router.push({ name: 'new-role' });
    }
  }
};
</script>

<style lang="scss" scoped>
.add-role-card {
  border-radius: 6px;
  border: 1px dashed var(--gray-200);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ::v-deep {
    margin-right: 18px;
  }
}

.v-card__text {
  width: auto;

  .add-role-card__text {
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: var(--gray-400);
    text-transform: uppercase;
  }

  .add-role-card__icon {
    font-size: 24px;
    margin-right: 16px;
    vertical-align: middle;
  }
}
</style>
