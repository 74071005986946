var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.setCurrentWidth,
          expression: "setCurrentWidth"
        }
      ],
      staticClass: "role-card",
      class: _vm.internalClass,
      attrs: { height: "139", "min-height": "139", light: "" },
      on: { click: _vm.clickInCard }
    },
    [
      _c("v-card-text", [
        _c(
          "div",
          { staticClass: "role-card__title-line" },
          [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.title,
                    expression: "title"
                  }
                ],
                staticClass: "role-card__title"
              },
              [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")]
            ),
            !_vm.internal
              ? _c("StIcon", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.roleTooltip,
                      expression: "roleTooltip"
                    }
                  ],
                  staticClass: "role-card__icon",
                  attrs: { "icon-name": "pencil", size: "13" }
                })
              : _vm._e()
          ],
          1
        ),
        _c("div", { staticClass: "role-card__users" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.userCount) +
              " " +
              _vm._s(_vm.$tc("Role.user", _vm.userCount)) +
              "\n    "
          )
        ])
      ]),
      _c(
        "v-card-actions",
        { staticClass: "role-card__actions" },
        [
          _vm._l(_vm.userAvatars, function(user) {
            return _c("StUserAvatar", {
              key: user.name,
              attrs: {
                user: user,
                tooltip: user.name,
                "user-image-path": "avatarUrl",
                size: "34",
                color: "grey lighten-4"
              }
            })
          }),
          _vm.showMore
            ? _c("StIcon", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.$t("Role.roleCard.showMoreIcon"),
                    expression: "$t('Role.roleCard.showMoreIcon')"
                  }
                ],
                staticClass: "role-card__icon-dots-horizontal",
                attrs: { "icon-name": _vm.showMoreIcon, size: "19" }
              })
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }