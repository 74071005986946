<template>
  <v-container
    class="role-cards"
    fluid
  >
    <div
      class="role-cards__role"
    >
      <AddRoleCard />
    </div>

    <div
      v-for="role in roles"
      :key="role.id"
      class="role-cards__role"
    >
      <RoleCard
        :id="role.id"
        :title="role.name"
        :users="role.users || []"
        :internal="role.internal"
        @on-role-clicked="editRole"
      />
    </div>
  </v-container>
</template>

<script>
import RoleCard from '@/components/role/RoleCard.vue';
import AddRoleCard from '@/components/role/AddRoleCard.vue';

export default {
  name: 'RoleCards',
  components: {
    AddRoleCard,
    RoleCard
  },
  props: {
    roles: {
      type: Array,
      required: true
    }
  },
  methods: {
    editRole({ goToTab, roleId }) {
      this.$router.push({ name: 'edit-role', params: { id: roleId, goToTab } });
    }
  }
};
</script>

<style lang="scss" scoped>
  .role-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(314px, 1fr));
    grid-auto-rows: 163px;

    ::v-deep {
      padding-left: 0;
      padding-right: 0;
    }
  }
</style>
