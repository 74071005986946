<template>
  <v-row
    class="role-list"
  >
    <v-container
      class="role-list__main-title"
      fluid
    >
      <h2 class="role-list-title">
        <b>{{ $t('Role.roleList.roleListTitle') }}</b>
      </h2>
      <p class="role-list__description">
        {{ $t('Role.roleList.roleListDescription') }}
      </p>
    </v-container>

    <v-container fluid>
      <h3 class="mb-6 role-list__green-title">
        <StIcon
          icon-name="hexagon-multiple"
        />
        {{ $t('Role.roleList.roleListSubtitle') }}
      </h3>

      <StReloadAndLoaderWrapper
        class="role-list__wrapper"
        :request-status="requestStatus"
        @reload="reload"
      >
        <template v-slot:wrappedComponent>
          <RoleCards
            v-if="success"
            :roles="roles"
          />
        </template>
      </StReloadAndLoaderWrapper>
    </v-container>
  </v-row>
</template>

<script>
import RoleCards from '@/components/role/RoleCards.vue';
import StIcon from '@stilingue/st-icon/StIcon';
import StReloadAndLoaderWrapper from '@stilingue/st-reload-and-loader-wrapper/StReloadAndLoaderWrapper';
import { mapState, mapActions } from 'vuex';
import requestStatusMixin from '@/mixins/request_status.mixin';

export default {
  name: 'RoleList',
  components: {
    RoleCards,
    StIcon,
    StReloadAndLoaderWrapper
  },
  mixins: [requestStatusMixin],
  data() {
    return {
      requestStatus: 'loading'
    };
  },
  computed: {
    ...mapState('role', [
      'roles'
    ])
  },
  async created() {
    try {
      await this.reload();
    } catch (e) {}
  },
  methods: {
    ...mapActions('role', [
      'getRoles',
      'getRoleUsers'
    ]),
    async reload() {
      this.requestStatus = 'loading';

      await this.getRoles();

      const allRoles = this.roles.map(role => {
        return this.getRoleUsers(role.id);
      });

      // Only show the cards after loading all the roles
      Promise.all(allRoles).then(() => {
        this.requestStatus = 'success';
      }).catch(() => {
        this.requestStatus = 'error';
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  .role-list-title {
    font-size: 21px;
  }

  .role-list {
    &__wrapper {
      min-height: 300px;
    }

    &__main-title {
      font-size: 22px;
      font-weight: bold;
      color: var(--gray-200);

      ::v-deep {
        padding-bottom: 0;
      }
    }

    &__content {
      padding-top: 0;
      max-width: 680px;

      &__description {
        font-size: 12px;
        color: var(--gray-200);
        text-align: justify;
      }
    }

    &__green-title {
      color: var(--green-agua-400);
      font-size: 18px;
      font-weight: bold;

      i {
        color: var(--green-agua-400);
      }
    }
  }
</style>
